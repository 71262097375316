import { atomEffect } from 'jotai-effect';
import {
  getAllPatientsAtom,
  getPatientsDealsAtom,
  getPatientsQuotesAtom,
  getPatientsVisitsAtom,
} from '../..';
import { isGettingAllPatientsAtom } from '../get-patients-extra-info-atom-effect';
import { assignDealsToPatients } from '../../../../../../utils';
import { accumulatedPatientsAtom } from '../../patients-atoms';
import { getPatientDealsAtom } from '../../getters/get-patient-deals-atom';

export const assignDealsToPatientsAtomEffect = atomEffect((get, set) => {
  const getAllPatients = get.peek(getAllPatientsAtom);
  const getPatientsDeals = get(getPatientsDealsAtom);
  const getPatientDeals = get(getPatientDealsAtom);

  set(isGettingAllPatientsAtom, true);

  const setIsNotLoading = () => {
    const getAllPatients = get.peek(getAllPatientsAtom);
    const isReportsLoading = get.peek(getPatientsQuotesAtom).isPending;
    const isVisitsLoading = get.peek(getPatientsVisitsAtom).isPending;
    if (!getAllPatients.isPending && !isReportsLoading && !isVisitsLoading) {
      set(isGettingAllPatientsAtom, false);
    }
  };

  if (getAllPatients.isPending || getPatientsDeals.isPending) return;

  if (getAllPatients.isError || getAllPatients.data == null || getPatientsDeals.isError) {
    setIsNotLoading();
    return;
  }

  console.log('get patient deals ', getPatientsDeals.data);

  const deals = getPatientsDeals.data ?? {};

  if (!getPatientDeals.isPending && getPatientDeals.data != null) {
    const patientId = Object.keys(getPatientDeals.data)[0];
    deals[patientId] = [...deals[patientId], ...getPatientDeals.data[patientId]];
    console.log('deals[patientId]', deals[patientId]);
  }

  const accumulatedPatients = get.peek(accumulatedPatientsAtom);
  const listToUse = accumulatedPatients.length === 0 ? getAllPatients.data : accumulatedPatients;
  const listWithDeals = assignDealsToPatients(listToUse, deals);
  set(accumulatedPatientsAtom, listWithDeals);

  setIsNotLoading();
});
